<template>
  <div
    class="text-editor"
    :class="{
      'text-editor--question': type === 'question',
      'text-editor--answer': type === 'answer',
      'text-editor--readonly': readonly,
    }"
  >
    <div
      v-if="type === 'question' && editor && readonly === false"
      class="text-editor__toolbar"
    >
      <Toolbar :type="toolbarType">
        <ToolbarItem
          name="Bold"
          :isActive="editor.isActive('bold')"
          :type="toolbarType"
          @click="editor.chain().focus().toggleBold().run()"
        >
          <font-awesome-icon icon="fa-solid fa-bold" />
        </ToolbarItem>
        <ToolbarItem
          name="Italic"
          :isActive="editor.isActive('italic')"
          :type="toolbarType"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <font-awesome-icon icon="fa-solid fa-italic" />
        </ToolbarItem>
        <ToolbarItem
          name="Underline"
          :isActive="editor.isActive('underline')"
          :type="toolbarType"
          @click="editor.chain().focus().toggleUnderline().run()"
        >
          <font-awesome-icon icon="fa-solid fa-underline" />
        </ToolbarItem>
        <ToolbarItem
          name="Align Left"
          :isActive="editor.isActive({ textAlign: 'left' })"
          :type="toolbarType"
          @click="editor.chain().focus().setTextAlign('left').run()"
        >
          <font-awesome-icon icon="fa-solid fa-align-left" />
        </ToolbarItem>
        <ToolbarItem
          name="Align Center"
          :isActive="editor.isActive({ textAlign: 'center' })"
          :type="toolbarType"
          @click="editor.chain().focus().setTextAlign('center').run()"
        >
          <font-awesome-icon icon="fa-solid fa-align-center" />
        </ToolbarItem>
        <ToolbarItem
          name="Align Right"
          :isActive="editor.isActive({ textAlign: 'right' })"
          :type="toolbarType"
          @click="editor.chain().focus().setTextAlign('right').run()"
        >
          <font-awesome-icon icon="fa-solid fa-align-right" />
        </ToolbarItem>
        <ToolbarItem
          name="Align Justify"
          :isActive="editor.isActive({ textAlign: 'justify' })"
          :type="toolbarType"
          @click="editor.chain().focus().setTextAlign('justify').run()"
        >
          <font-awesome-icon icon="fa-solid fa-align-justify" />
        </ToolbarItem>
        <ToolbarItem
          name="Text Color"
          :type="toolbarType"
          :popover="{
            component: TextColorPopover,
            props: {
              color: editor.getAttributes('textStyle').color || '#ffffff',
              onSet(color) {
                editor
                  .chain()
                  .focus()
                  .setColor(color)
                  .run();
              },
              onUnset() {
                editor
                  .chain()
                  .focus()
                  .unsetColor()
                  .run();
              },
            },
          }"
        >
          <div class="d-flex flex-column align-items-center">
            <font-awesome-icon icon="fa-solid fa-a" />
            <div
              :style="{
                height: '3px',
                width: 'calc(100% + 2px)',
                marginTop: '1px',
                backgroundColor: editor.getAttributes('textStyle').color || '#ffffff',
              }"
            />
          </div>
        </ToolbarItem>
      </Toolbar>
      <button
        v-if="type === 'question'"
        type="button"
        class="text-editor__toolbar-math"
        title="Math Equation"
        @click="openMathEquationModal"
      >
        <font-awesome-icon icon="fa-solid fa-square-root-variable" />
        <span>Math Equation</span>
      </button>
    </div>
    <div
      v-if="type === 'answer' && editor && readonly === false"
      class="text-editor__toolbar"
    >
      <Toolbar :type="toolbarType">
        <ToolbarItem
          name="Image"
          :type="toolbarType"
          :popover="{
            component: ImagePopover,
            props: {
              onInsert({ src, alt }) {
                editor
                  .chain()
                  .focus()
                  .setImage({ src, alt, title: alt })
                  .run();
              },
            },
          }"
        >
          <font-awesome-icon icon="fa-solid fa-image" />
        </ToolbarItem>
        <ToolbarItem
          name="Math Equation"
          :type="toolbarType"
          @click="openMathEquationModal"
        >
          <font-awesome-icon icon="fa-solid fa-square-root-variable" />
        </ToolbarItem>
      </Toolbar>
    </div>
    <EditorContent
      class="text-editor__field"
      :editor="editor"
      spellcheck="false"
    />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Image from '@tiptap/extension-image';

import Toolbar from './Toolbar.vue';
import ToolbarItem from './ToolbarItem.vue';
import MathEquationExtension from './extensions/MathEquationExtension';
import MathEquationModal, {
  properties as mathEquationModalProperties
} from './modals/MathEquationModal.vue';
import TextColorPopover from './popovers/TextColorPopover.vue';
import ImagePopover from './popovers/ImagePopover.vue';

export default {
  components: {
    Toolbar,
    ToolbarItem,
    EditorContent,
  },
  props: {
    type: {
      validator(value) {
        return ['question', 'answer'].includes(value);
      },
      default: 'question',
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update'],
  data() {
    return {
      editor: null,
      TextColorPopover,
      ImagePopover,
    };
  },
  methods: {
    openMathEquationModal() {
      this.$modal.show(
        MathEquationModal,
        {
          onInsert: (tex) => {
            if (tex) {
              this.editor
                .chain()
                .focus()
                .insertContent(`<span class="tex">${tex}</span>`)
                .run();
            }
          },
        },
        mathEquationModalProperties,
      );
    },
  },
  computed: {
    toolbarType() {
      return this.type === 'question' ? 'normal' : 'minimal';
    },
  },
  watch: {
    value(newValue) {
      if (this.editor.getHTML() !== newValue) {
        this.editor.commands.setContent(newValue, false);
      }
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      editable: this.readonly === false,
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
        Underline,
        TextAlign.configure({
          types: ['paragraph'],
          defaultAlignment: 'center',
        }),
        Color,
        TextStyle,
        Image.configure({
          allowBase64: true,
        }),
        MathEquationExtension,
      ],
      editorProps: {
        attributes: {
          class: 'creator-text-editor',
        },
      },
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML());
        this.$emit('update', this.editor.getHTML());
      },
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style scoped>
.text-editor {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.text-editor--answer {
  padding-top: 1rem;
}

.text-editor--question.text-editor--readonly {
  padding-top: 2.5rem;
}

.text-editor--answer.text-editor--readonly {
  padding-top: 3.25rem;
}

.text-editor__toolbar {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.text-editor__toolbar-math {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 1.75rem;
  padding: 0 0.75rem;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  transition: background-color 0.25s;
}

.text-editor__toolbar-math:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
</style>

<style>
.creator-text-editor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 0.5rem;
  outline: 0.25rem solid transparent;
  transition: outline 0.25s;
}

.text-editor--question .creator-text-editor {
  min-height: 9rem;
  padding: 1rem 2rem;
  font-size: 1.25rem;
}

.text-editor--answer .creator-text-editor {
  min-height: 7.5rem;
  padding: 1rem 1.5rem;
  font-size: 1rem;
}

.creator-text-editor:focus {
  outline-color: rgba(255, 255, 255, 0.25);
}

.creator-text-editor > * {
  margin: 0;
}

.creator-text-editor > * + * {
  margin-top: 0.25rem;
}

.creator-text-editor p {
  text-align: center;
}

.creator-text-editor p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  position: absolute;
  transform: translateX(-50%);
  height: 0;
  color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  transition: color 0.25s;
}

.creator-text-editor:focus p.is-editor-empty:first-child::before {
  color: rgba(255, 255, 255, 0.1);
}

.creator-text-editor u {
  text-decoration: underline;
}

.creator-text-editor img {
  outline: 3px solid transparent;
  transition: outline 0.25s;
}

.creator-text-editor img.ProseMirror-selectednode {
  outline: 2px solid rgba(255, 255, 255, 0.25);
  outline-offset: 2px;
}

.vm--container {
  z-index: 9999;
}

.vm--overlay {
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(2px);
}
</style>
